<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/zjzy_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<!-- 专家类型 -->
		<div class="block45"></div>
		<div class="huaxue_zscqyy_box">
			<!-- <div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">专家分类</div>
				<div class="type_desc fontsize14"></div>
			</div> -->
			<div class="block10"></div>
			<div class="huaxue_yybox w1260">
				<div class="yyitem" @click="handleType(index)" :style="'background:'+item.bgcolor" v-for="(item,index) in typeList" :key="index">
					<img class="img" :src="item.imgUrl || require('../assets/static/Frame888.png')"/>
					<div class="text1 fontsize18" style="color: #ffffff !important;">{{item.name}}</div>
					<div class="text2 fontsize16" style="color: #ffffff !important;">{{item.description || ""}}</div>
				</div>
			</div>
		</div>
		<!-- 专家 -->
		<div class="block45"></div>
		<div class="projectdock_expert_box">
			<div class="huaxue_home_type w1168" v-if="typeList.length>0">
				<div class="title fontsize38">{{typeList[typeIndex].name}}</div>
				<div class="typelist">
					<!-- <div class="typeobj fontsize22" @click="$util.routerPath('')">查看更多</div> -->
				</div>
			</div>
			<div class="expert_swiper_box w1168" v-if="swiperOptions.isshow">
				<div v-if="dataList.length>0" class="btn_prev" @click="slidePrev"><img src="../assets/static/icon_left_white.png"></div>
				<div v-if="dataList.length>0" class="btn_next" @click="slideNext"><img src="../assets/static/icon_right_white.png"></div>
				<div>
					<swiper ref="mySwiper" :options="swiperOptions">
					    <swiper-slide v-for="(item,index) in dataList" :key="index">
					    	<div class="slide_item">
					    		<div class="img"><img :src="item.imgUrl || require('../assets/static/logo.png')"></div>
								<div class="conbox">
									<div class="titlename fontsize18">{{item.title}}</div>
									<div class="block20"></div>
									<div class="desc fontsize14 clamp4"><span style="color: #247fe4;font-weight: 700;">擅长领域：</span>{{item.description}}</div>
									<div class="btn1 fontsize14" @click="$util.routerPath('/teamDetail',{uid:item.id})">查看详情</div>
								</div>
					    	</div>
					    </swiper-slide>
					</swiper>
				</div>
			</div>
		</div>
		
		<div class="block58"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
//页面引入swiper
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
	components: {
	    swiper,
	    swiperSlide
	},
	data() {
		return {
			typeList:[],//类型栏目
			typeIndex:0,//下表
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,
			colorList:["#FE7E7E","#FEBB7E","#72E09E","#7ED0FE"],
			swiperOptions:{
				isshow:false,
				slidesPerView: 4,
			},
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(16).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		if(document.body.clientWidth<750){
			//手机端
			this.swiperOptions.slidesPerView = 1
		}
		this.swiperOptions.isshow = true
		this.getZhuanjiatuanduiType()

	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//上一页
		slidePrev() {
		    this.$refs.mySwiper.swiper.slidePrev();
		},
		//下一页
		slideNext() {
		    this.$refs.mySwiper.swiper.slideNext()
		},
		//下标
		handleType(index){
			this.typeIndex = index
			//获取(文章专家)
			this.getZhuanjiatuandui()
		},
		//获取(文章类型)
		getZhuanjiatuanduiType() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 88,
			};
			this.$http.post('frontEnd/ProjectResource/getZhuanjiatuanduiType', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item, index) => {
						item["bgcolor"] = _this.colorList[index%4]
					})
					if(records.length>0){
						_this.typeList = records
					}
					//获取(文章专家)
					_this.getZhuanjiatuandui()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取(文章专家)
		getZhuanjiatuandui() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 888,
			};
			if(this.typeList.length>0){
				params['realTimeTypeId'] = this.typeList[this.typeIndex].id
			}
			this.$http.post('frontEnd/ProjectResource/getZhuanjiatuandui', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
